// import { ICtrManagementApi } from '@voicefoundry-cloud/vf-omp-shared/src/model/ctrManagement';
import { ApiClientBase } from './ApiClientBase';
import { getLogger } from '@vf/services/LoggingService';
import {
  CtrMetaByContactIdRequest,
  DeleteCtrRequest,
  ListCtrMetaRequest,
  QueryRequest,
  QueryResponse,
  lockCtrRequest,
  ToggleLockDeleteResponse,
} from '@voicefoundry-cloud/vf-omp-shared';
import { CtrMetaResponse } from '@voicefoundry-cloud/vf-omp-shared/src/model/ctrManagement/apiModels';

export interface ICtrManagementApi {
  ctrQuery: (body: QueryRequest) => Promise<QueryResponse>;
  deleteRecord: (body: DeleteCtrRequest) => Promise<ToggleLockDeleteResponse>;
  lockRecord: (body: lockCtrRequest) => Promise<ToggleLockDeleteResponse>;
  getCtrMetaByContactId: (body: CtrMetaByContactIdRequest) => Promise<CtrMetaResponse>;
  listCtrMeta: (body: ListCtrMetaRequest) => Promise<CtrMetaResponse>;
  getPresignedUrl: (s3Location: string) => Promise<{ presignedUrl: string }>;
}

export class CtrManagementApiClient extends ApiClientBase implements ICtrManagementApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('CtrManagementApiClient'), useAuth);
  }
  async ctrQuery(body: QueryRequest) {
    const result = await this.post(`/api/v2/ctr-management/ctr/query`, body, 840000);
    return (await result.json()) as QueryResponse;
  }
  async getCtrMetaByContactId(body: CtrMetaByContactIdRequest) {
    const result = await this.post('/api/v2/ctr-management/ctr/meta', body);
    return (await result.json()) as CtrMetaResponse;
  }
  async listCtrMeta(body: ListCtrMetaRequest) {
    const result = await this.post('/api/v2/ctr-management/ctr/meta', body);
    return (await result.json()) as CtrMetaResponse;
  }
  async deleteRecord(body: DeleteCtrRequest) {
    const result = await this.post('/api/v2/ctr-management/ctr/delete', body);
    return (await result.json()) as ToggleLockDeleteResponse;
  }
  async lockRecord(body: lockCtrRequest) {
    const result = await this.post('/api/v2/ctr-management/ctr/lock', body);
    return (await result.json()) as ToggleLockDeleteResponse;
  }
  async getPresignedUrl(s3Location: string) {
    const result = await this.post('/api/v2/ctr-management/audio/preSignedUrl', { s3Location });
    const jsonResult = (await result.json()) as { presignedUrl: string };
    console.log('PRESIGNED URL RESULT: ', JSON.stringify(jsonResult));
    return jsonResult;
  }
}
